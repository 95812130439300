// ==========================================================================
// LAYOUT / NAVIGATION
// ==========================================================================

.pagination {
	display: flex;
	align-items: center;
  	justify-content: center;
	list-style: none;
	margin-bottom: $base-spacing * 2;
	margin-top: 2em;
	
	.page-item {
		a {
			border: 1px solid $light-gray;
			border-right: 0;
			padding: ($small-spacing * 0.8) $small-spacing;
			font-weight: 800;
			line-height: 1;
			transition: all $base-duration $base-timing;

			&:hover {
				background: tint($light-gray, 40%);
			}
		}

		.disabled {
			color: $light-gray;
			cursor: default;
			&:hover { background: transparent; }
		}

		&.active {
			a:hover { background: transparent; }
		}
		&:first-child a {
			border-radius: 6px 0 0 6px;
		}
		&:last-child a {
			border-right: 1px solid $light-gray;
			border-radius: 0 6px 6px 0;
		}
	}

	@include grid-media($grid-md){
		align-items: left;
		justify-content: left;  
	}
}
