// ==========================================================================
// LAYOUT / SITE
// ==========================================================================


//
// Globals
// --------------------------------------------------------------------------

@-webkit-viewport {
width : device-width; }

@-moz-viewport {
width : device-width; }

@-ms-viewport {
width : device-width; }

@-o-viewport {
width : device-width; }

@viewport {
width : device-width; }


html {
	background: #fbfbfb;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten( $base-font-color, 75% );
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
	background-color: transparent;
	font-family : $base-font-family;
	line-height : $base-line-height;
	color : $base-font-color;
	-webkit-font-smoothing: antialiased;
}

::-moz-selection {
	background-color : lighten( $action-color, 10% );
	//color : $red;
	text-shadow : none; }

::selection {
	background-color : lighten( $action-color, 10% );
	//color : $red;
	text-shadow : none; }


//
// Content Wrappers
// --------------------------------------------------------------------------
.wrapper,
.wrapper--full {
	@include grid-container;
	padding-left: 2.5%;
	padding-right: 2.5%;
	width: auto;
	position: relative;
	
	@include grid-media($grid-sm){
		padding-left: 2%;
		padding-right: 2%;
	}
}

.wrapper {
	@include grid-media($grid-lg){
		padding-left: 0;
		padding-right: 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	@include grid-media($grid-xlg){
		width: 85%;
	}

	@include grid-media($grid-xxlg){
		width: 1300px;
	}
}

.module {
	background: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
	padding: $base-spacing;
	padding-top: $base-spacing;
	margin-bottom: $base-spacing;
	border-radius: 4px;
}

#primary-content {
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;

}


//
// Site Layout
// --------------------------------------------------------------------------


// Main Banner
#banner-primary {
	background-color: $jh-grey;
	background-image: url("../img/banner-right.jpg");
	background-repeat: no-repeat;
	background-position: right top;
	background-size: contain;

	#banner-inner {
		padding: $small-spacing;
	}
	
	.module { 
		margin-bottom: 0;
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
	
	header {
		@include grid-column(12);
		margin-bottom: $small-spacing;
		text-align: center;

		h2 {
			color: darken($jh-orange, 5%);
		}
	}

	@include grid-media($grid-sm){

		h2 {
			margin-bottom: $small-spacing /2;
		}
		.module { 
			margin-left: 2%;
			margin-right: 2%;
			padding-left: 0.5em;
			padding-right: 0.5em;
		}
	}

	@include grid-media($grid-md){
		background-position: 105% top;

		header { text-align: left; }
		h2 { margin-bottom: 0; }

		.module { 
			margin-bottom: 0;
			margin-left: auto;
			margin-right: auto;
			padding-left: 1em;
			padding-right: 1em;
		}

		#banner-inner {
			background-image: url("../img/banner-left.png");
			background-repeat: no-repeat;
			background-position: -80px top;
			background-size: contain;
			padding-top: $base-spacing * 2;
			padding-bottom: $base-spacing * 2;
			padding-left: $small-spacing;
			padding-right: $small-spacing;
		}
	}
}

.search-form {

	label {	@include hide-visually(); }

	input,
	.select { 
		font-size: 85%; 
		padding: $small-spacing;
	}

	.btn {
		font-size: 110%;
		width: 100%;
	}

	.form-group {
		margin-bottom: $small-spacing;
	}

	#form-keyword { @include grid-column(12); }
	#form-location { @include grid-column(6); }
	#form-distance { @include grid-column(6); }
	.form-group-btn { @include grid-column(12); }

	// Masthead version on sub pages
	#masthead & {
		@include grid-column(12);

		@include grid-media($grid-sm){
			@include grid-column(10);
			padding-top: $small-spacing / 2;
			padding-bottom: $small-spacing / 2;

			form { @include grid-collapse; }

			.btn {
				font-size: 80%;
				padding: ($base-spacing * .65) 0;
			}

			.form-group {
				margin-bottom: 0;
			}

			#form-keyword { @include grid-column(3); }
			#form-location { @include grid-column(3); }
			#form-distance { @include grid-column(3); }
			.form-group-btn { @include grid-column(3); }
		}

		@include grid-media($grid-md) {
			.btn {
				font-size: 100%;
				padding: ($base-spacing * .5) 0;
			}
			#form-keyword { 
				@include grid-column(3); 
				@include grid-push(1);
			}
			#form-location { @include grid-column(3); }
			#form-distance { @include grid-column(2); }
			.form-group-btn { @include grid-column(3); }
		}

		@include grid-media($grid-xlg) {
			#form-keyword { 
				@include grid-column(2); 
				@include grid-push(4);
			}
			#form-location { @include grid-column(2); }
			#form-distance { @include grid-column(2); }
			.form-group-btn { @include grid-column(2); }
		}

		 

	}

	// Big version for home page
	#banner-primary & {
		@include grid-media($grid-md){
			input,
			.select { 
				font-size: 100%; 
				padding: $small-spacing;
			}

			.btn {
				font-size: 100%;
				padding: ($base-spacing * .65) $base-spacing;
			}

			#form-keyword { @include grid-column(4); }
			#form-location { @include grid-column(3); }
			#form-distance { @include grid-column(2); }
			.form-group-btn { @include grid-column(3); }
		}

	}
}



.popular-searches {
	@include grid-column(12);
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;
	
	hgroup {
		text-align: center;
		margin-bottom: $base-spacing;
	}
	h3 {
		color: $medium-gray;
		text-transform: uppercase;
		letter-spacing: 0.15em;
	}
	h2 {
		color: $jh-black;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: $base-spacing;
	}

	li {
		margin:($small-spacing / 2) 4px;
		a {
			padding: ($small-spacing / 2) ($base-spacing * 0.8);
			background: $jh-grey;
			color: #666;
			border-radius: 100em;
			font-size: 0.9em;
			transition: background-color $base-duration $base-timing;

			&:hover {
				background: darken( $jh-grey, 10% );
			}
		}
	}

	@include grid-media($grid-md){
		padding-top: $base-spacing * 2;

		h3 {
			font-size: 110%;
		}

	}
}


// Side column
#search-results {
	//@include grid-column(12);
	@include grid-media($grid-md){
		@include grid-column(5);
	}

	header {
		margin-bottom: $base-spacing;
		text-align: center;
	}
}
//Main column
#results-focus {
	@include grid-media($grid-md){
		@include grid-column(7);
	}

}

.listings {
	margin-bottom: $base-spacing;
	border-top: 1px solid rgba(0,0,0,0.05);
}

.job {
	position: relative;
	border-right: 1px solid transparent;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	border-left: 5px solid transparent;
	padding: $small-spacing;
	padding-left: 4em;
	margin: 0 -10px;
	transition: all $base-duration $base-timing;

	a {
		color: $jh-blue;
		letter-spacing: 0;
		transition: all $base-duration $base-timing;
		&:hover {
			color: $jh-green;
		}
	}

	.job-title {
		margin-bottom: 2px;
		text-transform: normal;
		font-size: 100%;
	}
	.branding {
		position: absolute;
		top: 1em;
		left: 0.6em;
		width: 40px;
		height: 40px;
	}
	.job-meta {
		font-size: 80%;
	}
	.posted-on {
		font-size: 80%; 
		color: $medium-gray;
		text-align: right;
	}

	&.active {
		@include gradient-horizontal(#ffffff, #fbfbfb, 60%, 100%);
		border-left-color: tint($jh-orange, 20% );
		border-right: 1px solid transparent;
	}

	&:hover {
		//background: #fff;
		@include gradient-horizontal(#ffffff, #fbfbfb, 60%, 100%);
		border-left-color: #eee;
		//border-right: 1px solid #fbfbfb;

		&.active {
			border-left-color: $jh-orange;
		}
	}

	@include grid-media($grid-md){
		margin: 0;
	}
}

.job-full {
	border-radius: 6px;
	background: #ffffff;
	box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 4px 10px rgba(0,0,0,0.05);

	header {
		background: $jh-blue;
		border-radius: 4px 4px 0 0;
		padding: $base-spacing;
		padding-top: $base-spacing * 2;

		h2 {
			color: $jh-grey;
			margin-bottom: $small-spacing;
			line-height: 1em;
		}
		> div {
			display: flex;
			justify-content: space-between;
		}

		.btn--holder {
			padding-top: $small-spacing;
			@include grid-media($grid-lg){ 
				padding-top: 0;
			}
		}
		.btn {
			padding: $small-spacing $base-spacing;
			border: 2px solid shade( $action-color, 20% );
			background: transparent;
			color: tint( $action-color, 20% );
			&:hover {
				background-color: $action-color;
				border-color: $action-color;
				color: $jh-blue;
			}
		}
	}
	ul {
		list-style: disc;
		margin-left: 2em;
	}
	li {
		margin-bottom: $small-spacing;
	}
		
	.job-meta { 
		color: tint( $jh-green, 20% );

		div {
			display: block;
			width: 100%;
		}
		@include grid-media($grid-lg){ 
			div {
				display: inline;
				width: auto;
				margin-right: 0.5em;
				&:after {
					content: "\2022";
					display: inline-block;
					margin-left: 0.5em;
				}
				&:last-child:after {
					content: '';
				}
			}
		}
	}

	.branding {
		width: 100%;
		height: auto;

		a {
			display: block;
		}
	}

	.job-details {
		@include grid-container;
		padding: $small-spacing;	
		padding-top: $base-spacing;
		border: 3px solid rgba(0,0,0,0.2);
		border-width: 0 2px 2px 2px;
		border-radius: 0 0 4px 4px;

		aside {
			display: none;
		}
		.job-description {
			@include grid-column(12);
			padding-bottom: $base-spacing;
		}
	}

	.btn-holder {
		@include grid-column(12);
		text-align: center;
		padding-top: $base-spacing;
		padding-bottom: $small-spacing / 2;
		border-top: 1px solid rgba(0,0,0,0.05);
	}

	@include grid-media($grid-sm){ 
		.job-details {
			padding: $base-spacing $base-spacing $base-spacing $small-spacing;
			
			aside {
				display: block;
				@include grid-column(2);
			}
			.job-description {
				@include grid-column(12);
			}
		}
	}
	@include grid-media($grid-md){
		.job-details {
			
			aside {
				display: none;
			}
			.job-description {
				@include grid-column(12);
				max-height: 860px;
				overflow: auto;
			}
		}
	} 
	@include grid-media($grid-lg){
		.job-details {
			aside {
				display: block;
				@include grid-column(2);
			}
			.job-description {
				@include grid-column(12);
			}
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
}
