// ==========================================================================
// LAYOUT / FOOTER
// ==========================================================================

#page-footer {
	padding-top: $base-spacing * 2;
	padding-bottom: $base-spacing * 4;
	text-align: center;
	border-top: 1px solid $light-gray;

	
	p, li {
		font-size: 90%;
	}

	ul {
		float: right;
		li {
			float: left;
		}
	}

	@include grid-media($grid-md){
		text-align: left;
		font-size: 1em;

		.copyright,
		nav {
			@include grid-column(6);
		}
		.copyright {
			
		}

		nav {
			a {
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}
}