// ==========================================================================
//  LAYOUT / MASTHEAD
// ==========================================================================

#masthead {
	background: #fff;
	padding-top: $small-spacing / 2;
	box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
	z-index: 10;
	position: relative;

	.wrapper {
	}

	.logo {
		@include grid-column(3);
		@include grid-push(4.5);
		margin-bottom: $small-spacing;

		svg {
			fill: $jh-black;
		}
	}
	

	@include grid-media($grid-sm){
		.logo { 
			@include grid-push(0);
			@include grid-column(2); }
	}
	@include grid-media($grid-xlg){
		.logo { @include grid-column(1.5); }
	}
}
