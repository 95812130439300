// ==========================================================================
// LAYOUT / FORMS
// ==========================================================================
$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 400;
  margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $_form-box-shadow;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: 0 0 1px 3px rgba($jh-orange, 0.4);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}


//
// Special Select
// --------------------------------------------------------------------------
.select {
  display: block;
  font-size: $base-font-size;
  padding: $small-spacing;
  font-size: 1.25em;
  color: $dark-gray;
  line-height: 1.3;
  width: 100%;
  max-width: 100%; 
  box-sizing: border-box;
  margin: 0;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.03);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $base-background-color;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  border: solid 1px rgba(0,0,0,0.2);

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba($jh-orange, 0.4);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
  }

  option {
    font-weight:normal;
  }
}


//
// Form Groups
// --------------------------------------------------------------------------
.form-group {
  margin-bottom: $base-spacing;
  position: relative;

  > label {
    font-size: 1em;
    margin-bottom: 0;
    padding: 3px;
  }

  span {
    label {
      font-size:90%;
    }

    .desc {
      font-size:70%;
      text-transform: uppercase;
      padding-left: $base-spacing / 3;
      padding-top: 4px;
      letter-spacing: 0.04em;
    }
  }


  &--btn {
    text-align: center;
    padding-top: $small-spacing;

    .btn {
  //    background: $go-red;
   //   color: $go-yellow;

      &:hover {
     //   background: $go-yellow;
    //    color: $go-blue;
      }
      
      @include grid-media($grid-lg){
        padding: 1.5em 3em;
        font-size: $base-font-size * 1.2;
      }

    }
  }

}

/* Parsley Errors */
.parsley-errors-list {
  margin-bottom: 1em;
  padding: 0.5em;
  color: red;

  &.filled li {
    font-size: 0.9em;
    font-weight: 800;
  }

}

.parsley-error {
  border: 1px solid red;
  box-shadow: 0 0 10px rgba(red, 0.2);
  margin-bottom: 0;
}

.form-container {

  .legal {
    font-size: 70%;
    
      @include grid-media($grid-md){
        font-size: 75%;
      }
      @include grid-media($grid-lg){
        font-size: 85%;
      }
  }

}
